import React from 'react';
import * as Self from './module';
import { registerModule } from '../../app/modules/reactModules';
import Container from '../../components/global/Container';
import Heading from '../../components/global/Heading';
import BrandOverviewItem from '../../components/BrandOverviewItem';
import { useBreakpoint } from '../../app/providers/breakpointProvider';

export const getServerSideProps = async () => {
  return {
    props: {
      loading: false,
    },
    maxage: 60,
    staleWhileRevalidate: 300,
  };
};

const BrandsOverviewModule = ({ title, brands, ...p }) => {
  const columns = useBreakpoint().select({
    mobile: 2,
    tablet: 3,
    laptop: 4,
    desktopSmall: 5,
    desktop: 6,
  });

  return (
    <Container {...p} color="aliceBlue" pt="30px" pb="30px" gap="20px" flex col>
      {!!title && <Heading title={title} />}
      <Container
        plain
        grid
        columns={`repeat(${columns}, 1fr)`}
        gap="10px"
        as="div"
      >
        {brands?.map(({ link: { content, href }, logo }, ix) => (
          <BrandOverviewItem
            key={ix}
            href={href}
            name={content}
            logoSrc={logo.src}
          />
        ))}
      </Container>
    </Container>
  );
};

export default BrandsOverviewModule;

registerModule({ BrandsOverview: Self });
