import React from 'react';
import StyledBrandOverviewItem from './BrandOverviewItem.style';
import Picture from './global/Picture';
import Typography from './global/Typography';

const BrandOverviewItem = ({ slug, name, logoSrc, ...p }) => {
  return (
    <StyledBrandOverviewItem {...p}>
      <Picture
        alt={`${name} logo`}
        width={20}
        height={20}
        images={{
          1: logoSrc,
        }}
      />
      <Typography variant="controlStrongSmall">{name}</Typography>
    </StyledBrandOverviewItem>
  );
};
export default BrandOverviewItem;
