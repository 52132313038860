import styled from '@emotion/styled';

export default styled.a`
  display: block;
  padding: 5px 15px;
  min-height: 50px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.bahamaBlue};
  border: solid thin ${({ theme }) => theme.colors.quartz};
  border-radius: 4px;
  display: flex;
  gap: 10px;
  align-items: center;
  transition: border-color 150ms linear;
  ${({ theme }) => theme.media.hover} {
    &:hover {
      border-color: ${({ theme }) => theme.colors.bahamaBlue};
    }
  }
  &:active {
    color: ${({ theme }) => theme.colors.pacificBlue};
    border-color: ${({ theme }) => theme.colors.bahamaBlue};
  }
`;
